import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios'

ChartJS.register(ArcElement, Tooltip, Legend);



function Demidoughnut2(props) {
  const [Data, setData] = useState([]);

  const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";
  useEffect(() => {
    const getData = async () => {
      if (props.pref) {
        //console.log("test test test"+props.pref)
        var valeur = props.pref
        if(valeur.includes("'")){
            valeur = valeur.replace(/'/g, "''");
        }
        if(props.com){
          var valeur1 = props.com
          if(valeur1.includes("'")){
              valeur1 = valeur1.replace(/'/g, "''");
          }
          var apicom;
          if(props.annee){
            apicom=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Délai moyen par jour PC&arrondissement=` + valeur1 + `&annee=`+props.annee;
          }else{
            apicom=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Délai moyen par jour PC&arrondissement=` + valeur1
          }
              
  
          await axios.get(apicom, {
          "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
          console.log(response.data.data)
          setData(response.data.data)
          console.log(response.data.data)
        })
  
        }else{
          var apipre;
        if(props.annee){
          apipre=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Délai moyen par jour PC&prefecture=` + valeur + `&annee=`+props.annee;
        }else{
          apipre=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Délai moyen par jour PC&prefecture=` + valeur;
        }
          await axios.get(apipre, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {
            console.log(response.data.data)
            setData(response.data.data)
            console.log(response.data.data)
          })
        }
        
      } 
      
      else {
        var preapi;
        if(props.annee){
          preapi=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Délai moyen par jour PC&annee=`+props.annee
        }else{
          preapi=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Délai moyen par jour PC`
        }
        await axios.get(preapi, {
          "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
        
          setData(response.data.data)
        })
      }
    };
      getData();
  }, [props.pref,props.com,props.annee]);
  //console.log(Data.length)
  var data={}
  if(Data.length === 0){
   data = {
    labels: ['', 'Range'],
    datasets: [{
      data: [
        { value: 0},
        { value: 0}
      ],
      backgroundColor: [
        'rgba(135,206,250,0.6)', 'transparent'
      ],
      borderWidth: 5,
      hoverOffset: 4,
      borderColor: 'rgba(120,150,250,0.4)',
      tension: 0.2,
      hidden: false,
    }
    ]
  }
}
if(Data.length !== 0){
  //var x;
  var y;
  if (Data.length % 2 === 0) {
     y = Data.find(item => item.CATEGORIE_DESC === "Petits projets")['Avg DUREE_DELIVRANCE']
    //x = Data[2]["Avg duree"]
  } else {
     y = '0'
    // x = Data[1]["Avg duree"]
  }
   data = {
    labels: ['Certificat de conformité', 'Range'],
    datasets: [{
      data: [
        { value: parseFloat(y?y:'0')},
        { value:18}
      ],
      backgroundColor: [
        'rgba(135,206,250,0.6)', 'transparent'
      ],
      borderWidth: 5,
      hoverOffset: 4,
      borderColor: 'rgba(120,150,250,0.4)',
      tension: 0.2,
      hidden: false,
    }
    ]
  }
}
  var options = {
    cutout: 35,
    // rotation: props.rotation,
    //circumference: props.circumference,
    datalabels: {
      color: '#fff',
    },
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    rotation: -90,
    circumference: 180,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: 'The title',
            font: {
              size: '60'
            }
          }]

      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        //beginAtZero: true,
        display: false,
        barPercentage: 0.4,
      }
    },

  }

  return (

    <div class="bg-gray-100	 col-span-1 rounded-lg  drop-shadow-md shadow-md shadow-blue-100/500" >
    <div >
      <div class="pr-5 pl-10">
      <p class="font-sans md:font-serif font-medium mt-2"> Délai moyen délivrance Petits projets {props.annee?' en '+props.annee:''}</p>
      </div>
    
      <div>
        <Doughnut data={data} options={options}
          style={{ height: "80px" }}
        />
      </div>
    </div>
    <div class="pl-4"><p class="md:font-serif font-medium pl-16"> Délai moyen/J: {y}</p></div>
    

  </div>


  )
}


export default Demidoughnut2