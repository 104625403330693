import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, BarElement, LinearScale, CategoryScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'

import axios from 'axios'


ChartJS.register(
    LinearScale,
    BarElement,
    CategoryScale
)


function BarChart(props) {
    const [Data, setData] = useState([]);
      const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";
    const [checkboxes, setCheckboxes] = useState({
        dataset1: true,
        dataset2: true
    });
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        console.log(checked)
        setCheckboxes({ ...checkboxes, [name]: checked });
    };

    useEffect(() => {
        
    const getData = async () => {
        if (props.prefcommun) {
            var valeur = props.prefcommun
            if (valeur.includes("'")) {
                valeur = valeur.replace(/'/g, "''");
            }
            if(props.com){
                var valeur1 = props.com
                if (valeur1.includes("'")) {
                    valeur1 = valeur1.replace(/'/g, "''");
                }
                await axios.get(urlapi + `getApiecoglobal?workspace=Urbanisme-DML_RFC_RCP/Evolution des avis des membres de commission des autorisations de démolition&column=MEMBRECOM&table=AVIS_COMMISSIONDEM&value=`+valeur, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    console.log(response.data.data)
                    setData(response.data.data)
                })

            }else{
                await axios.get(urlapi + `getApiecoglobal?workspace=Urbanisme-DML_RFC_RCP/Evolution des avis des membres de commission des autorisations de démolition&column=MEMBRECOM&table=AVIS_COMMISSIONDEM&value=`+valeur, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    console.log(response.data.data)
                    setData(response.data.data)
                })
            }
          

        } else {

            await axios.get(urlapi + `getApiecoglobal?workspace=Urbanisme-DML_RFC_RCP/Evolution des avis des membres de commission des autorisations de démolition`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                setData(response.data.data)
            })
        }

    };
        getData();
    }, [props.prefcommun,props.com]);
var data={}
    if (Data.length === 0) {
         data = {
            labels: ['2020', '2021', '2022', '2023', '2024'],
            datasets: [{
                label: 'test1',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'rgba(194, 128, 162, 0.8)'
                ],
                borderWidth: 2,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: false,
            },
            {
                label: 'test2',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'green'
                ],
                borderWidth: 2,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: false,

            }
            ]
        }

    }

    if (Data.length !== 0) {
        var n = Data?.filter(x => x['Avis'] === 'Favorable').map(x => x['DOSSIER_ID Count'])
        var l = Data?.filter(x => x['Avis'] === 'Défavorable').map(x => x['DOSSIER_ID Count'])
        if (l.length !== n.length) {
            if (l.length > n.length) {
                n.unshift(0)
            } if (l.length < n.length) {
                l.unshift(0)
            }
        }
         data = {
            labels: ['2020', '2021', '2022', '2023'],
            datasets: [{
                label: 'Favorable',
                data: n,
                backgroundColor: [
                    'rgb(190 242 100)'
                ],
                borderWidth: 2,
                borderColor: 'green',
                tension: 0.2,
                hidden: !checkboxes.dataset1,
            },
            {
                label: 'Défavorable',
                data: l,
                backgroundColor: [
                    'green'
                ],
                borderWidth: 2,
                borderColor: 'rgb(190 242 100)',
                tension: 0.2,
                hidden: !checkboxes.dataset2,

            }
            ]
        }

    }
    var options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            }
        },
        title: {
            display: true,


        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Date commision'
                }
            },
            y: {
                grid: {
                    display: true,
                },
                title: {
                    display: true,
                    text: 'Nombre avis'
                }
            }
        },
        legend: {
            labels: {
                fontSize: 26
            }

        }
    }
    return (
        <div class="bg-gray-100 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500 respodiv " style={{ display: "block", height: "100%" }}>
            <div className="ml-2 items-center pt-3 pl-5 pr-5" style={{width:'100%'}}>
                <p class="font-sans md:font-serif font-medium pb-1- ">Evolution des avis des membres de commission des autorisations de démolition</p>
                <div class="mb-2">
                </div>
            </div>

            <div class="flex flex-row " >
                <div class="ml-3" style={{ display: "block", height: "260px",width:'80%' }}>
                    <Bar data={data} options={options} />
                </div>
                <div class="flex flex-col justify-center items-center place-content-center pt-20" style={{ display: "block", width: "20%" }}>

                    <div >
                        <div >

                            <input
                                type="checkbox"
                                name="dataset1"
                                checked={checkboxes.dataset1}
                                onChange={handleCheckboxChange}
                                class="mr-1 ml-3 h-4 w-4 rounded-full shadow"
                            />
                            <label class=""> Grand Projets </label>


                        </div>
                        <div>


                            <input
                                type="checkbox"
                                name="dataset2"
                                checked={checkboxes.dataset2}
                                onChange={handleCheckboxChange}
                                class="bg-sky-200 mr-1 ml-3 h-4 w-4 rounded-full shadow"
                            />
                            <label class="mr-3"> Petit Projet  </label>


                        </div>


                    </div>



                </div>
            </div>
        </div>

    )
}

export default BarChart