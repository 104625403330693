import { PieChart, Pie, Sector,ResponsiveContainer,Cell } from 'recharts';
import React, { useCallback, useState,useEffect  } from "react";
import axios from 'axios'

const COLORS = ["#87CEFA", "#7996FA"];


const renderActiveShape = (props,nmbrdossier,yvalue) => {
  //console.log('nmbrdossier ',nmbrdossier);
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 6;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) *4}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      > {`${payload.value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) *2}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};


export const CustomActiveShapePieChart = (props) => {



  
  const [Data, setData] = useState([]);

  const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";
  useEffect(() => {
    const getData = async () => {
      if(props.pref){
        var valeur = props.pref
        if(valeur.includes("'")){
            valeur = valeur.replace(/'/g, "''");
        }
        var apicompre;
        if(props.com){
          var valeur1 = props.com
          if(valeur1.includes("'")){
              valeur1 = valeur1.replace(/'/g, "''");
          }
          if(props.annee){
            apicompre= urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par catégorie&arrondissement=` + valeur1 + `&annee=`+props.annee
            //apicompre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par catégorie?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&kanalytics_OUTPUT_FORMAT=JSON&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_CRITERIA="ARRONDISSEMENT"='`+valeur1+ `' AND "ANNEE"='`+props.annee+`'`;
          }else{
             apicompre= urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par catégorie&arrondissement=` + valeur1 
            //apicompre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par catégorie?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&kanalytics_OUTPUT_FORMAT=JSON&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_CRITERIA="ARRONDISSEMENT"='`+valeur1+`'`
          }
          await axios.get(apicompre, {
            "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
         console.log(response.data.data)
            setData(response.data.data)
        })
  
        }else{
          if(props.annee){
            apicompre=  urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par catégorie&prefecture=` + valeur + `&annee=`+props.annee;
          }else{
            apicompre=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par catégorie&prefecture=` + valeur ;
          }
          console.log('prefecture',apicompre)
          
          await axios.get(apicompre, {
            "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
         console.log(response.data.data)
            setData(response.data.data)
        })
    
        }
     
  
      }
      
      
      else{
        var apiel;
          if(props.annee){
            //apiel=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par catégorie?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&kanalytics_OUTPUT_FORMAT=JSON&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_CRITERIA="ANNEE"='`+props.annee+`'`
           apiel=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par catégorie&annee=`+props.annee;
          }else{
            apiel = urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par catégorie`;
          }
        await axios.get(apiel, {
          "Content-Type": "application/json; charset=utf-8"
      }).then((response) => {
       console.log(response.data.data)
          setData(response.data.data)
      })
      }
       
    };
      getData();
  }, [props.pref,props.com,props.annee]);
var x=0
var y=0
//var nmbr=0
if (Data.length !== 0){

//console.log(Data[0]['categorie_desc Count'])
if(Data.length %2 === 0){
  x=Data.find(item => item.CATEGORIE_DESC === "Grands projets")
  y=Data.find(item => item.CATEGORIE_DESC === "Petits projets")


}else{
  x=Data.find(item => item.CATEGORIE_DESC === "Grands projets")
  y=Data.find(item => item.CATEGORIE_DESC === "Petits projets")

}

var data = [
  { name: 'Grands projet', value: parseInt(x?x['DOSSIER_ID Count']:'0')},
  { name: 'Petits Projet', value: parseInt(y?y['DOSSIER_ID Count']:'0') },
  ];
}
console.log('ana x',x)

  const [activeIndex, setActiveIndex] = useState(0);
  var valeurr=x;
  const [nmbr, setNmbr] = useState(valeurr);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
      if(index===1){
   setNmbr(y);

      }else{
setNmbr(x);

      }
    
    },
    [setActiveIndex,x,y]
  );

  return (
    <ResponsiveContainer width={350} height={222} >
    <PieChart >
      <Pie
        activeIndex={activeIndex}
        activeShape={(props) => renderActiveShape(props, nmbr,x)}
        data={data}
        cx={165}
        cy={95}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
        onMouseEnter={onPieEnter}

      >
           {data?data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        )):data}
        </Pie>
    </PieChart>
    </ResponsiveContainer>
  );
};
