import React, { useState, useEffect } from 'react'
import axios from 'axios'
import 'antd/dist/antd.min.css';
import '../components/Style.css';
import HomeLayout from "../components/HomeLayout";
import Breadcrumbs from '../components/BreadCrumbs';
import LineChart from '../components/charts/ChartPH/LineChart';

import Select from 'react-select';

import BarChart from '../components/charts/ChartPH/BarChart';
import { ReactComponent as ReactLogo } from '../components/svgs_img/arrow.svg';
import { CustomActiveShapePieChart } from '../components/charts/ChartPH/CustomActiveShapePieChart';
import Demidoughnut1 from '../components/charts/ChartPH/Demidoughnut1';
import Demidoughnut2 from '../components/charts/ChartPH/Demidoughnut2';
import Barchartrechart from '../components/charts/ChartPH/Barchartrechart';
import Piepercentage from '../components/charts/ChartPH/Piepercentage';

const StatPH = (props) => {

    const [prefOptions, setprefOptions] = useState([]);
    const [comOptions, setcomOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [prefecture, setprefecture] = useState('');
    const [years, setyears] = useState([]);
    const [year, setyear] = useState('');
    const [commune, setcommune] = useState('');
    console.log(selectedOption)
      const urlapi ="/front/karazal/kas/api-ps/permisConstruire/";
  
    const getData = async () => {
       
        await axios.get(urlapi + `getApiInfosEco?workspace=Urbanisme-PC-PH-Service Rendu/prefectureListPH`, {
          "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
          console.log(response.data.data)
          var mydata = response.data.data
          console.log('here we go',mydata);
          //console.log(mydata)
          var table=[]
          var obj={}
          for (let i = 0; i < mydata.length; i++) {
            obj.label=mydata[i]['PREFECTURE']
            obj.value=mydata[i]['PREFECTURE']
            table.push(obj);
            obj={}
          }
          setprefOptions(table)
        
        })
      
  
    };
   
    useEffect(() => {
             const currentYear = new Date().getFullYear();
        const startYear = 2017;
        const endYear = currentYear;
      
        const yearsArray = Array.from({ length: endYear - startYear + 1 }, (_, index) => {
          const year = startYear + index;
          return { label: year, value: year };
        });
        setyears(yearsArray)
        const getDatacom = async () => {
            var valeur = prefecture
            if(valeur.includes("'")){
                valeur = valeur.replace(/'/g, "''");
            }
           
            await axios.get(urlapi + `getApiecoglobal?workspace=Urbanisme-PC-PH-Service Rendu/PrefectureCommuneListPH&column=prefecture&value=`+valeur, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
             // console.log(response.data.data)
              var mydata = response.data.data
              console.log(mydata)
              var table=[]
              var obj={}
              for (let i = 0; i < mydata.length; i++) {
                obj.label=mydata[i]['commune']
                obj.value=mydata[i]['commune']
                table.push(obj);
                obj={}
              }
              if(prefecture){
                setcomOptions(table)
              }else{
                setcomOptions([])
              }
              
             // console.log("this is length ==> "+table.length)
            })
          
      
        };
      getData();
      getDatacom();
    }, [prefecture]);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 40,
            backgroundColor: 'rgb(241 245 249)', //set a fixed height for the control element
        }),
        menu: (provided) => ({
            ...provided,
            maxHeight: 300, //set a fixed max-height for the menu element
        }),
    };

   
  
    const handleSelectChange = (selectedOption) => {
        //console.log(selectedOption == null)
        if (selectedOption === null) {
            setSelectedOption(null);
            setprefecture('');
        } else {
            setSelectedOption(selectedOption);
            console.log(selectedOption)
            setprefecture(selectedOption['value']);
        }


    };
    const handleSelectChangecom = (selectedOption) => {
        //console.log(selectedOption == null)
        if (selectedOption === null) {
            setSelectedOption(null);
            setcommune('');
        } else {
            setSelectedOption(selectedOption);
            console.log(selectedOption)
            setcommune(selectedOption['value']);
        }
    };
    const handleSelectChangyear = (selectedOption) => {
        console.log(selectedOption == null)
        if (selectedOption === null) {
            setSelectedOption(null);
            setyear('');
        } else {
            setSelectedOption(selectedOption);
            console.log('year year',selectedOption)
            setyear(selectedOption['value']);
        }
    };
    return (
        <>
            <HomeLayout>
                <div style={{ margin: "0 5% 0 5%" }} class="pb-5">
                    <Breadcrumbs home="Urbanisme" icon="ph2" text="Dossiers permis d'habiter et certificat de conformité e-déposés" />
                    <div class="selectprefcom" style={{display:'flex' ,width: "100%",flexWrap:'wrap',gap:'20px',paddingTop:'10px',paddingBottom:'10px'}}>
                        <div >
                            <label>Préfecture et Province:</label>
                            <Select  placeholder="Choisir une préfecture" options={prefOptions} onChange={handleSelectChange} styles={customStyles} isClearable={true} isMulti={false} />
                        </div>
                        <div >
                            <label>Commune et Arrondissement:</label>
                            <Select placeholder="Choisir une commune" options={comOptions} onChange={handleSelectChangecom} styles={customStyles} isClearable={true} isMulti={false} />
                        </div>
                        <div >
                            <label>Année de délivrance :</label>
                            <Select placeholder="sélectionner une année" options={years} onChange={handleSelectChangyear} styles={customStyles} isClearable={true} isMulti={false}/>
                        </div>

                    </div>
                    <div style={{ height: "100%",paddingTop:'10px' }} class="flex flex-row mb-2">
                        <ReactLogo />
                        <label class="font-sans md:font-serif font-semibold text-lg ml-2"> Nombre de permis délivrés</label>
                    </div>

                    <div class="rfcparti1 ">
                        <BarChart pref={prefecture} com={commune} annee={year} />
                        <div class="bg-gray-100 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500" >
                            <div class="pcdounat">
                                <div style={{display:'flex', justifyContent:'center',marginTop:'35px'}}>
                                <span class="font-sans md:font-serif font-medium"> % catégorie permis délivrés</span>
                                </div>
                                <div>
                                    <CustomActiveShapePieChart pref={prefecture} com={commune} annee={year} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{width:'100%',height:'20px'}}></div>
                    <div style={{ height: "100%",paddingTop:'12px',paddingBottom:'12px' }} class="flex flex-row mb-2">
                        <ReactLogo />
                        <span class="font-sans md:font-serif font-semibold text-lg ml-2" style={{width:'57%'}}>Distributiondes permis d’habiter / Certificats de conformité délivrés par nature de projet / type de projet</span>
                    </div>
                    <div class="rfcparti1">
                        <Barchartrechart pref={prefecture} com={commune} annee={year}/>
                        <div class="bg-gray-100	 col-span-1 row-span-2 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500" style={{ display: "block", width: "350px", height: "310px" }} >
                        <div style={{display:'flex', justifyContent:'center',paddingTop:'8px',paddingLeft:'20px',alignItems:'center'}}>
                                <span class="font-sans md:font-serif font-medium" > Distribution des permis de construire délivrés par type de projet</span>
                                </div>
                            <div class="pl-10">
                             
                                <Piepercentage pref={prefecture} com={commune} />
                            </div>

                        </div>
                    </div>


                    <div style={{ height: "35px",paddingTop:'10px',paddingBottom:'10px' }} class="flex flex-row mt-4">
                        <ReactLogo />
                        <label class="font-sans md:font-serif font-semibold text-lg ml-2"> Délais de délivrance des Permis d’habiter / Certificat de conformité</label>
                    </div>
                    <div class="rfcparti1">
                        <LineChart pref={prefecture} com={commune} annee={year}/>
                        <div class="monthscontainer">
                        <Demidoughnut1 pref={prefecture} com={commune} annee={year}/>
                        <Demidoughnut2 pref={prefecture}com={commune} annee={year}/>
                        </div>
                    </div>   
                </div>
            </HomeLayout>

        </>
    )
}
export default StatPH;