import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import axios from 'axios'


import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


function LineChart(props) {

    const [checkboxes, setCheckboxes] = useState({
        dataset1: true,
        dataset2: true
    });
    const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        console.log(checked)
        setCheckboxes({ ...checkboxes, [name]: checked });
    };

    const [Data, setData] = useState([]);

    useEffect(() => {
     
    const getData = async () => {
        const monthNames = [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ];
        if (props.pref) {
            var valeur;
          if(props.com){
             valeur = props.com
            if(valeur.includes("'")){
                valeur = valeur.replace(/'/g, "''");
            }

            var apicom;
            if(props.annee){
               
                apicom= urlapi+`getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des dossier Permis d'habiterr par mois&arrondissement=`+ valeur +`&annee=`+props.annee;
        
            }else{
               
                apicom=  urlapi+`getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des dossier Permis d'habiterr&arrondissement=`+ valeur;
            }

            await axios.get(apicom, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                if(props.annee){
                    setData(response.data.data.filter(item => item["Date de délivrance"] !== "").map(item => {
                        const monthNumber = parseInt(item["Date de délivrance"].split('-')[0], 10);
                        const monthName = monthNames[monthNumber - 1]; // Month names array is 0-indexed
                        return { ...item, "Date de délivrance": monthName };
                      }))
                }else{
                    setData(response.data.data.filter(item => item["Date délivrance"] !== ""))
                }
            })

          }else{
             valeur = props.pref
            if(valeur.includes("'")){
                valeur = valeur.replace(/'/g, "''");
            }
            if (valeur ==='PREFECTURE DE RABAT'){
                valeur ='PRÉFECTURE DE RABAT'
            }
            var apiPref;
            if(props.annee){
                 
                apiPref=urlapi+`getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des dossier Permis d'habiterr par mois&prefecture=`+ valeur +`&annee=`+props.annee;
            console.log(apiPref)
            }else{
                apiPref=urlapi+`getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des dossier Permis d'habiterr&prefecture=`+ valeur
            }
            await axios.get(apiPref, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                if(props.annee){
                    setData(response.data.data.filter(item => item["Date de délivrance"] !== "").map(item => {
                        const monthNumber = parseInt(item["Date de délivrance"].split('-')[0], 10);
                        const monthName = monthNames[monthNumber - 1]; // Month names array is 0-indexed
                        return { ...item, "Date de délivrance": monthName };
                      }))
                }else{
                    setData(response.data.data.filter(item => item["Date délivrance"] !== ""))
                }
            })
          }
        } else {
            var apiels;
            if (props.annee) {
                
                apiels =urlapi+`getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des dossier Permis d'habiterr par mois&annee=`+ props.annee
                console.log(apiels)
            } else {
                apiels =urlapi+`getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des dossier Permis d'habiterr`
            }
            await axios.get(apiels, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                if(props.annee){
                    setData(response.data.data.filter(item => item["Date de délivrance"] !== "").map(item => {
                        const monthNumber = parseInt(item["Date de délivrance"].split('-')[0], 10);
                        const monthName = monthNames[monthNumber - 1]; // Month names array is 0-indexed
                        return { ...item, "Date de délivrance": monthName };
                      }))
                }else{
                    setData(response.data.data.filter(item => item["Date délivrance"] !== ""))
                }
              
            })
        }
    };
        getData();
    }, [props.pref,props.com,props.annee]);


    
var data={}
    if (Data.length === 0) {
         data = {
            labels: ['2019', '2020', '2021', '2022', '2023'],
            datasets: [{
                label: 'test1',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'rgba(194, 128, 162, 0.8)'
                ],
                borderWidth: 2,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset1,
            },
            {
                label: 'test2',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'green'
                ],
                borderWidth: 3,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset2,

            }
            ]
        }
    }
    function getUnique(array) {
        var uniqueArray = [];
        var i = 0;
        // Loop through array values
        for (i = 0; i < array.length; i++) {
            if (uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        // Sort uniqueArray in ascending order (from ancient to recent years)
        uniqueArray.sort(function(a, b) {
            return a - b;
        });
        return uniqueArray;
    }
    if (Data.length !== 0) {
       
        var n=Data?.filter(x=>x['CATEGORIE'] === 'Grands projets').map(x=>x['Moyenne de délivrance'])
        var l=Data?.filter(x=>x['CATEGORIE'] === "Petits projets").map(x=>x['Moyenne de délivrance'])
        console.log('hada rah n',n);
        if(l.length !== n.length){
            if(l.length > n.length ){
                n.unshift(0)
            }if(l.length < n.length ){
                l.unshift(0)
            }
        }
       
         data = {
            labels: getUnique(Data?.map(x=>x['Date de délivrance'])),
            datasets: [{
                label: "Durée moyenne (j)",
                data: n,
                backgroundColor: [
                    'rgba(194, 128, 162, 0.8)'
                ],
                borderWidth: 4,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset1,
            },
            {
                label: "Durée moyenne (j)",
                data: l,
                backgroundColor: [
                    'green'
                ],
                borderWidth: 4,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset2,

            }
            ]
        }
    }
    var options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        title: {
            display: true,


        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Date délivrance'
                }
            },
            y: {
                grid: {
                    display: true,
                },
                title: {
                    display: true,
                    text: 'Durée moyenne (j)'
                }
            }
        },
        legend: {
            labels: {
                fontSize: 26
            }

        }
    }
    return (
        <div class="bg-gray-100	 row-span-2 col-span-2 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500 mt-8 pb-2 respodiv" style={{ display: "block", height: "100%" }}>
            <div className="ml-2 items-center pt-3">
                <p class="font-sans md:font-serif font-medium pb-1" style={{paddingLeft:'15px'}}>Evolution  de la durée moyenne de délivrance des dossier Permis d'habiter </p>
                <div class="mb-2">
                   
                </div>
            </div>

            <div class="flex flex-row " >
                <div class="ml-3 mt-3" style={{ display: "block", width: "80%", height: "260px" }}>

                    <Line data={data} options={options}/>

                </div>
                <div class="flex flex-col justify-center items-center place-content-center pt-20" style={{ display: "block", width: "20%" }}>
                    <div >

                        <input
                            type="checkbox"
                            name="dataset1"
                            checked={checkboxes.dataset1}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc1'
                        />
                        <label class="">Grands projets</label>


                    </div>
                    <div>


                        <input
                            type="checkbox"
                            name="dataset2"
                            checked={checkboxes.dataset2}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc2'
                        />
                        <label class="mr-3"> Petit projets </label>


                    </div>


                </div>
            </div>


        </div>

    )
}

export default LineChart