import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios'

ChartJS.register(ArcElement, Tooltip, Legend);


function Demidoughnut1(props) {
  const [Data, setData] = useState([]);

 const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";

  useEffect(() => {
    const getData = async () => {
      if (props.pref) {
        var valeur;
        if(props.com){
           valeur = props.com
          if(valeur.includes("'")){
              valeur = valeur.replace(/'/g, "''");
          }
          
          await axios.get(urlapi+`getApiInfosRfc?workspace=Urbanisme-DML_RFC_RCP/délai moyen par RFC&arrondissementetap=`+ valeur, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {
            
            setData(response.data.data)
          
          })

        }else{
           valeur = props.pref
          if(valeur.includes("'")){
              valeur = valeur.replace(/'/g, "''");
          }
          await axios.get(urlapi+`getApiInfosRfc?workspace=Urbanisme-DML_RFC_RCP/délai moyen par RFC&prefectureEtap=`+ valeur, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {
            
            setData(response.data.data)
          
          })
          
        }
     
      } else {
        await axios.get(urlapi+`getApiInfosRfc?workspace=Urbanisme-DML_RFC_RCP/délai moyen par RFC`, {
          "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
         
          setData(response.data.data)
    
        })
      }
    };
    getData();
  }, [props.pref,props.com]);
  // console.log(Data)
  var data ={}
  if (Data.length === 0) {
     data = {
      labels: ['Grands Projet', 'Range'],
      datasets: [{
        data: [
          { value: 0, label: "" },
          { value: 0, label: "" }
        ],
        backgroundColor: [
          'rgba(120,150,250,0.4)', 'transparent'
        ],
        borderWidth: 5,
        hoverOffset: 4,
        borderColor: 'rgba(135,206,250,0.4)',
        tension: 0.2,
        hidden: false,
      }
      ]
    }
  }

  if (Data.length !== 0) {

      var x = Data?.map(x=>parseFloat(x['Avg duree']))
      var somme = 0
      for (let i = 0; i < x.length; i++) {
        somme += x[i];
      }
      console.log(somme)

    
var avg =parseFloat(somme/x.length).toFixed(2)
     data = {
      labels: ["Permis de Construire", "Range"],
      datasets: [{
        data: [
          { value: avg },
          { value: 18 }
        ],
        backgroundColor: [
          'rgba(120,150,250,0.4)', 'transparent'
        ],
        borderWidth: 5,
        hoverOffset: 4,
        borderColor: 'rgba(135,206,250,0.4)',
        tension: 0.2,
        hidden: false,
      }
      ]
    }
  }
  var options = {
    cutout: 60,
    // rotation: props.rotation,
    //circumference: props.circumference,
    datalabels: {
      color: '#fff',
    },
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    rotation: -90,
    circumference: 180,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: 'The title',
            font: {
              size: '60'
            }
          }]

      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        //beginAtZero: true,
        display: false,
        barPercentage: 0.3,
      }
    },

  }
  /* <p class="font-sans pb-1 pt-2"> délai moyen/J pour les  dossiers d’autorisation de réféction</p>*/


  return (
    <div style={{ height: "135px",}}>
      <div class="pl-14 pr-5">
      <p class="font-sans md:font-serif font-medium pb-1">Délai moyen/J pour les  dossiers d’autorisation de réféction</p>
      
      </div>
      
          <Doughnut data={data} options={options} />
          <p class="font-bold text-2xl pl-36"> {avg}</p>
      </div>


  )
}

export default Demidoughnut1