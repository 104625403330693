import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import '../components/Style.css';

//import Iframe from 'react-iframe';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { ReactComponent as Arrow1 } from '../components/svgs_img/arrow-down-solid.svg';
import { ReactComponent as Arrow } from '../components/svgs_img/caret-up-solid.svg';

const IndecateurglobalEco = (props) => {
    const navigate = useNavigate();
    const [DecANRMonthStat, setDecANRMonthStat] = useState("");
    const [ECMonthStat, setECMonthStat] = useState("");
    const [OANMonthStat, setOANMonthStat] = useState("");
    const [AFPMonthStat, setAFPMonthStat] = useState("");
    const [ODPRTMonthStat, setODPRTMonthStat] = useState("");



    const [DecANRMonthpré, setDecANRMonthpré] = useState("");
    const [ECMonthpré, setECMonthpré] = useState("");
    const [OANMonthpré, setOANMonthpré] = useState("");
    const [AFPMonthpré, setAFPMonthpré] = useState("");
    const [ODPRTMonthpré, setODPRTMonthpré] = useState("");
      const urlapi ="/front/karazal/kas/api-ps/permisConstruire/";
    
    useEffect(() => {
        const Data2 = async () => {
            //urlapi + `getApiecoglobal?workspace=Economique/moisSD`;
            await axios
              .get(urlapi + `getApiecoglobal?workspace=Economique/moisSD`, {
                "Content-Type": "application/json; charset=utf-8",
              })
              .then((response) => {
                console.log(response.data.data);
                var l = response.data.data.map((x) =>
                  parseInt(x["DOSSIER_ID Nombre distinct"])
                );
                var somme = 0;
                for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setDecANRMonthStat(somme);
                //console.log(somme)
              });
        };

        const monthstatEC = async () => {
            
            await axios.get(urlapi + `getApiecoglobal?workspace=Economique/ce mois-ci EC`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['dossier_id Nombre distinct']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                    somme += l[i];
                }
                setECMonthStat(somme)
            })
        }
        const monthstatOAN = async () => {
            await axios.get(urlapi + `getApiecoglobal?workspace=Economique/OAN ce mois-ci`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Nombre distinct']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                    somme += l[i];
                }
                setOANMonthStat(somme)
            })
        }

        const monthstatAFP = async () => {

            await axios.get(urlapi + `getApiInfosEco?workspace=Economique/ce mois-ci AFP`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['dossier_id Nombre distinct']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                    somme += l[i];
                }
                setAFPMonthStat(somme)
            })
        }

        const monthstatODPRT = async () => {
            
            await axios.get(urlapi + `getApiecoglobal?workspace=Economique/ce mois-ci ODPRT`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['dossier_id Nombre distinct']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                    somme += l[i];
                }
                setODPRTMonthStat(somme)
            })
        }

        //month précedent

        const DatapréDecANR = async () => {
            await axios.get(urlapi + `getApiecoglobal?workspace=Economique/mois précédent  SD`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Nombre distinct']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                    somme += l[i];
                }
                setDecANRMonthpré(somme)
            })
        };

        const monthpreEC = async () => {
            await axios.get(urlapi + `getApiecoglobal?workspace=Economique/mois precedent EC`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['dossier_id Nombre distinct']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                    somme += l[i];
                }
                setECMonthpré(somme)
            })

        }
        const monthspréOAN = async () => {
            await axios.get(urlapi + `getApiecoglobal?workspace=Economique/OAN mois precedent`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Nombre distinct']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                    somme += l[i];
                }
                setOANMonthpré(somme)
            })
        }

        const monthspréAFP = async () => {
            
            await axios.get(urlapi + `getApiecoglobal?workspace=Economique/mois precedent AFP`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['dossier_id Nombre distinct']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                    somme += l[i];
                }
                setAFPMonthpré(somme)
            })
        }

        const monthspréODPRT = async () => {
            await axios.get(urlapi + `getApiecoglobal?workspace=Economique/mois precedent ODPRT`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['dossier_id Nombre distinct']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                    somme += l[i];
                }
                setODPRTMonthpré(somme)
            })
        }

        Data2();
        monthstatEC();
        monthstatOAN();
        monthstatAFP();
        monthstatODPRT();


        DatapréDecANR();
        monthpreEC();
        monthspréOAN();
        monthspréAFP();
        monthspréODPRT();
    }, []);





    const handleClick = () => {
        // This function will be executed when the div is clicked
        navigate('/OpenData/DeclarationANR');
        // You can perform any other actions here
    };
    const handleClick1 = () => {

        navigate('/OpenData/ANR');

    };
    const handleClick2 = () => {
        navigate('/OpenData/OAN');
    };
    const handleClick3 = () => {

        navigate('AFP');

    };
    const handleClick4 = () => {

        navigate('ODPRT');

    };



    return (
        <div class="indica">

            <div class="indicateur">
                <div class='rounded cursor-pointer donneesOvert' onClick={handleClick} style={{ backgroundColor: '#1C5182' }} >
                    <div >
                        <span style={{ fontFamily: 'Arial', fontSize: '19px', fontWeight: 'bold', lineHeight: '24px', color: 'white' }}>Simples déclarations</span>
                    </div>
                    <div>
                        <span class='normal text-5xl font-extrabold' style={{ color: '#F0F2F5' }}>{DecANRMonthStat < DecANRMonthpré ? (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {DecANRMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {DecANRMonthStat}
                            <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                        </div>

                        )}</span>
                    </div>
                    <div >
                        <span class='normal text-xl font-mediumbold' style={{ color: 'white' }}>Mois précédent: {DecANRMonthpré}</span>
                    </div>
                    
                </div>

                <div class='rounded cursor-pointer donneesOvert' onClick={handleClick1} style={{ backgroundColor: '#52A352' }} >
                    <div >
                        <span style={{ fontFamily: 'Arial', fontSize: '13px', fontWeight: 'bold', lineHeight: '24px', color: 'white' }}>Activités non réglementées</span>
                    </div>
                    <div >


                        <span className='normal text-5xl font-extrabold' style={{ color: 'white' }}>
                            {ECMonthStat < ECMonthpré ? (
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                    {ECMonthStat}
                                    <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                                </div>
                            ) : (

                                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {ECMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                            </div>

                            )}
                        </span>
                    </div>
                    <div >
                        <span class='normal text-xl font-mediumbold' style={{ color: '#F0F2F5' }}>Mois précédent: {ECMonthpré}</span>
                    </div>
                </div>


                <div class='rounded cursor-pointer donneesOvert' onClick={handleClick2} style={{ backgroundColor: '#5B98E3' }} >
                    <div >
                        <span style={{ fontFamily: 'Arial', fontSize: '13px', fontWeight: 'bold', lineHeight: '24px', color: 'white' }}>Occupations domaine public</span>
                    </div>
                    <div >
                        <span class='normal text-5xl font-extrabold ' style={{ color: 'white' }}>{OANMonthStat < OANMonthpré ? (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {OANMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {OANMonthStat}
                            <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                        </div>


                        )}</span>
                    </div>
                    <div >
                        <span class='normal text-xl font-mediumbold' style={{ color: '#F0F2F5' }}>Mois précédent: {OANMonthpré}</span>
                    </div>
                </div>
                <div class='rounded cursor-pointer donneesOvert' onClick={handleClick3} style={{ backgroundColor: '#2A9729' }} >
                    <div >
                        <span style={{ fontFamily: 'Arial', fontSize: '20px', fontWeight: 'bold', lineHeight: '24px', color: 'white' }}>Affichage publicitaire</span>
                    </div>
                    <div >
                        <span class='normal text-5xl font-extrabold' style={{ color: 'white' }}>{AFPMonthStat < AFPMonthpré ? (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {AFPMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {AFPMonthStat}
                            <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                        </div>

                        )}</span>
                    </div>
                    <div >
                        <span class='normal text-xl font-mediumbold' style={{ color: '#F0F2F5' }}>Mois précédent: {AFPMonthpré}</span>
                    </div>
                </div>
                <div class='rounded cursor-pointer donneesOvert' onClick={handleClick4} style={{ backgroundColor: '#16A0DD' }} >
                    <div>
                        <span style={{ fontFamily: 'Arial', fontSize: '20px', fontWeight: 'bold', lineHeight: '24px', color: 'white' }}>Réseaux publics</span>
                    </div>
                    <div >
                        <span class='normal text-5xl font-extrabold ' style={{ color: 'white' }}>{ODPRTMonthStat < ODPRTMonthpré ? (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {ODPRTMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {ODPRTMonthStat}
                            <div style={{ width: '20px', height: '20px' }}><Arrow  /></div>

                        </div>


                        )}</span>
                    </div>
                    <div >
                        <span class='normal text-xl font-mediumbold' style={{ color: '#F0F2F5' }}>Mois précédent: {ODPRTMonthpré}</span>
                    </div>
                </div>
                <div class='rounded cursor-pointer donneesOvert' onClick={handleClick4} style={{ backgroundColor: '#7BBFE7' }} >
                    <div>
                        <span style={{ fontFamily: 'Arial', fontSize: '20px', fontWeight: 'bold', lineHeight: '24px', color: 'white' }}>Etablissement classé</span>
                    </div>
                    <div >
                        <span class='normal text-5xl font-extrabold ' style={{ color: 'white' }}>{ODPRTMonthStat < ODPRTMonthpré ? (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {0}
                              

                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {0}


                        </div>


                        )}</span>
                    </div>
                    <div >
                        <span class='normal text-xl font-mediumbold' style={{ color: '#F0F2F5' }}>Mois précédent: {0}</span>
                    </div>
                </div>
            </div>
     
        </div>

    )
}
export default IndecateurglobalEco;
