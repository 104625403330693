import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, BarElement, LinearScale, CategoryScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'

import axios from 'axios'

ChartJS.register(
    LinearScale,
    BarElement,
    CategoryScale
)


function BarChart(props) {




    const [checkboxes, setCheckboxes] = useState({
        dataset1: true,
        dataset2: true
    });
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        console.log(checked)
        setCheckboxes({ ...checkboxes, [name]: checked });
    };



    const [Data, setData] = useState([]);

 const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";
    useEffect(() => {
        const monthNames = [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ];
        const getData = async () => {
            if (props.pref) {
                var valeur;
                if (props.com) {
                    valeur = props.com
                    if (valeur.includes("'")) {
                        valeur = valeur.replace(/'/g, "''");
                    }
                    var apicom;
                    if(props.annee){
                        apicom=urlapi+'getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier délivré par type autorisation par mois&arrondissement='+ valeur +`&annee=`+props.annee;
                  console.log(apicom)
                    }else{
                        apicom =
                          urlapi +
                          "getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier délivré par type autorisation&prefecture=" +
                          valeur; 
                    }
                    await axios.get(apicom, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        if(props.annee){
                            setData(response.data.data.filter(item => item["Date délivrance"] !== "").map(item => {
                                const monthNumber = parseInt(item["Date délivrance"].split('-')[0], 10);
                                const monthName = monthNames[monthNumber - 1]; // Month names array is 0-indexed
                                return { ...item, "Date délivrance": monthName };
                              }))
                        }else{
                            setData(response.data.data.filter(item => item["Date délivrance"] !== ""))
                        }
                    })

                } else {
                    valeur = props.pref
                    if (valeur.includes("'")) {
                        valeur = valeur.replace(/'/g, "''");
                    }
                    if (valeur === 'PREFECTURE DE RABAT') {
                        valeur = 'PRÉFECTURE DE RABAT'
                    }
                    var apiPref;
                    if(props.annee){
                       
                        apiPref= urlapi+'getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier délivré par type autorisation par mois&prefecture='+ valeur +`&annee=`+props.annee;
                    console.log(apiPref)
                    }else{
                       
                        apiPref= urlapi+'getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier délivré par type autorisation&prefecture='+ valeur
                    }
                    await axios.get(apiPref, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        if(props.annee){
                            setData(response.data.data.filter(item => item["Date délivrance"] !== "").map(item => {
                                const monthNumber = parseInt(item["Date délivrance"].split('-')[0], 10);
                                const monthName = monthNames[monthNumber - 1]; // Month names array is 0-indexed
                                return { ...item, "Date délivrance": monthName };
                              }))
                        }else{
                            setData(response.data.data.filter(item => item["Date délivrance"] !== ""))
                        }
                    })
                }
            } else {
                var apiels;
                if (props.annee) {
                    apiels = urlapi+'getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier délivré par type autorisation par mois&annee=' + props.annee
                    console.log(apiels)
                } else {
                    apiels = urlapi+'getApiInfosph?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier délivré par type autorisation'
                    //apiels = `https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier délivré par type autorisation?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON`
                }
                await axios.get(apiels, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {

                    if(props.annee){
                        setData(response.data.data.filter(item => item["Date délivrance"] !== "").map(item => {
                            const monthNumber = parseInt(item["Date délivrance"].split('-')[0], 10);
                            const monthName = monthNames[monthNumber - 1]; // Month names array is 0-indexed
                            return { ...item, "Date délivrance": monthName };
                          }))
                    }else{
                        setData(response.data.data.filter(item => item["Date délivrance"] !== ""))
                    }
                    //console.log(response.data.data)
              
                })

            }

        };
        getData();
    }, [props.pref, props.com, props.annee]);







    //  console.log(Data.length)

    //console.log(startDate.getMonth())
    var data = {}
    if (Data.length === 0) {
        data = {
            labels: ['2019', '2020', '2021', '2022', '2023'],
            datasets: [{
                label: 'test1',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'rgba(194, 128, 162, 0.8)'
                ],
                borderWidth: 2,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset1,
            },
            {
                label: 'test2',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'green'
                ],
                borderWidth: 2,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset2,

            }
            ]
        }
    }

    function getUnique(array) {
        var uniqueArray = [];
        var i = 0
        // Loop through array values
        for (i = 0; i < array.length; i++) {
            if (uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    if (Data.length !== 0) {
        var n;
        var l;
        if (props.annee) {
            n = Data?.filter(x => x["typePermis"] === "Certificat de conformité").map(x => x["Nombre dossier"])
            l = Data?.filter(x => x["typePermis"] === "Permis d'habiter").map(x => x["Nombre dossier"])
        } else {
            n = Data?.filter(x => x["Type d'autorisation"] === "Certificat de conformité").map(x => x["Nombre dossier"])
            l = Data?.filter(x => x["Type d'autorisation"] === "Permis d'habiter").map(x => x["Nombre dossier"])
        }

        if (l.length !== n.length) {
            if (l.length > n.length) {
                n.unshift(0)
            } if (l.length < n.length) {
                l.unshift(0)
            }
        }

        const hj = l.length - n.length;

        // Create an array with 'hj' number of zeros
        const zerosArray = Array.from({ length: hj }, () => 0);
        data = {
            labels: getUnique(Data?.map(x => x["Date délivrance"])),
            datasets: [{
                label: 'Nombre dossier',
                data: [...zerosArray, ...n],
                backgroundColor: [
                    'rgb(134 239 172)'
                ],
                borderWidth: 2,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset1,
            },
            {
                label: 'Nombre dossier',
                data: [...l],
                backgroundColor: [
                    'green'
                ],
                borderWidth: 2,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset2,

            }
            ]
        }
    }

    var options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            }
        },
        title: {
            display: true,


        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Date délivrance'
                }
            },
            y: {
                grid: {
                    display: true,
                },
                title: {
                    display: true,
                    text: 'Nombre dossiers'
                },

            }
        },
        legend: {
            labels: {
                fontSize: 26
            }

        }
    }
    return (
        <div class="bg-gray-100 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500 respodiv" style={{ display: "block", height: "100%" }}>
            <div className="ml-2 items-center pt-1">
                <p class="font-sans md:font-serif font-font-medium pb-1" style={{ paddingLeft: '15px' }}>Evolution annuelle du nombre de permis délivrés par type</p>
                <div class="mb-2">
                    <label>

                        <div class="w-48">

                        </div>
                    </label>
                </div>
            </div>

            <div class="flex flex-row " >
                <div class="ml-3" style={{ display: "block", width: "73%", height: "250px" }}>

                    <Bar data={data} options={options} />

                </div>
                <div class="flex flex-col justify-center items-center place-content-center pt-20" style={{ display: "block", width: "25%" }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <input
                            type="checkbox"
                            name="dataset1"
                            checked={checkboxes.dataset1}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc3'
                        />
                        <span class="" style={{ fontWeight: '12px' }}> Certificat de conformité </span>


                    </div>
                    <div>


                        <input
                            type="checkbox"
                            name="dataset2"
                            checked={checkboxes.dataset2}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc4'
                        />
                        <label class="mr-3"> Permis d'habiter  </label>


                    </div>


                </div>
            </div>


        </div>

    )
}

export default BarChart