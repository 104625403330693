import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {

  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const COLORS = ["#737373","#0088FE", "#00C49F", "#FFBB28", "#FF8042","#C2B280"];

export default function Barchartrechart(props) {


    const [Data, setData] = useState([]);

  const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";

    useEffect(() => {
        const getData = async () => {
            if (props.pref) {

                var valeur = props.pref
                if (valeur.includes("'")) {
                    valeur = valeur.replace(/'/g, "''");
                }
                if (props.com) {
                    var valeur1 = props.com
                    if (valeur1.includes("'")) {
                        valeur1 = valeur1.replace(/'/g, "''");
                    } 
                    var apicom;
                    if(props.annee){
                         //urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par nature de projet&arrondissement='` + valeur1 + `'&annee=`+props.annee
                        apicom= urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par nature de projet&arrondissement=` + valeur1 + `&annee=`+props.annee;
                    }else{
                        apicom=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par nature de projet&arrondissement=` + valeur1
                    }

                    await axios.get(apicom, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        //console.log(response.data.data)
                        setData(response.data.data)
                    })

                } else {
                    var apipref;
                    if(props.annee){
                        //urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par nature de projet&PREFECTURE='` + valeur + `'&annee=`+props.annee;
                        apipref=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par nature de projet&prefecture=` + valeur + `&annee=`+props.annee;
                    }else{
                        apipref=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par nature de projet&prefecture=` + valeur;
                    }
                    await axios.get(apipref, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        //console.log(response.data.data)
                        setData(response.data.data)
                    })

                }
            }
            else {
                var apiel;
                if(props.annee){
                    apiel=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par nature de projet&annee=`+props.annee
                }else{
                    apiel=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par nature de projet`
                }
                await axios.get(apiel, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    console.log(response.data.data)
                    setData(response.data.data)

                })
            }

        };
        getData();
    }, [props.pref, props.com,props.annee]);
    //console.log(Data.length)
    var data=[];

    if (Data.length === 0) {
        data = {
            labels: ['2018', '2018', '2019', '2020', '2021', '2022', '2020'],
            datasets: [{
                label: 'test1',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'rgba(16, 185, 129, 0.8)'
                ],
                borderWidth: 2,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: false,
            },
            {
                label: 'test2',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'green'
                ],
                borderWidth: 2,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: false,

            }
            ]
        }
    }
    //console.log(Data)

   

 
    if (Data.length !== 0) {
    
        //console.log("hello i'm N"+n[0])
       


        for (let i = 0; i < Data.length; i++) {
            let typeDesc = Data[i].NATURE_DESC;
            let distinctCount = parseInt(Data[i]['DOSSIER_ID Nombre distinct']);
            let typeDescabrr = Data[i].NATURE_DESC.substring(0, 7)+'..';
            data.push({ name: typeDesc, pv: distinctCount,abrr:typeDescabrr });

    }
    console.log(data);















    return (
        <div className="bg-gray-100 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500 respodiv" style={{ display: "block", height: "80%" }}>
         <div className="ml-2 items-center pt-3" style={{width:'100%'}}>
                <p class="font-sans md:font-serif font-medium pb-1" style={{paddingLeft:'15px'}}>Distribution des permis de construire délivrés par nature de projet {props.annee?' en '+props.annee:''}</p>
                <div class="mb-2">
                </div>
            </div>
          <div className="flex flex-row mt-3" style={{height:'260px'}}> 
            <div className="ml-3" style={{ display: "block", height: "400px", width: "100%" }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={600} height={500}>
                  <Pie
                    dataKey="pv"
                    data={data}
                    startAngle={180}
                    endAngle={0}
                    cx="50%"
                    cy="50%"
                    outerRadius={160}
                    fill="#8884d8"
                    label
                  >
                   {data?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
                  </Pie>
                  <Tooltip formatter={(value) => `${value} dossiers`} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      );
      
      
}
}