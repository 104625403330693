import React, { useState, useEffect } from 'react'
import axios from 'axios'
import 'antd/dist/antd.min.css';
import '../components/Style.css';
import HomeLayout from "../components/HomeLayout";
import Breadcrumbs from '../components/BreadCrumbs';
import LineChart from '../components/charts/ChartPC/LineChart';
import LineChart2 from '../components/charts/ChartPC/LineChart2';
import Select from 'react-select'
import BarChart from '../components/charts/ChartPC/BarChart';
import Demidoughnut1 from '../components/charts/ChartPC/Demidoughnut1';
import Demidoughnut2 from '../components/charts/ChartPC/Demidoughnut2';
import { ReactComponent as ReactLogo } from '../components/svgs_img/arrow.svg';
import { CustomActiveShapePieChart } from '../components/charts/ChartPC/CustomActiveShapePieChart';
import PieChartWithNeedle from '../components/charts/ChartPC/PieChartWithNeedle';
import Piepercentage from '../components/charts/ChartPC/Piepercentage';
import Barchartrechart from '../components/charts/ChartPC/Barchartrechart';




const StatPC = () => {
 

    const [prefOptions, setprefOptions] = useState([]);
    const [comOptions, setcomOptions] = useState([]);
    const [years, setyears] = useState([]);
    const [year, setyear] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [prefecture, setprefecture] = useState('');
    const [commune, setcommune] = useState('');
    console.log(selectedOption);
    const urlapi = "/front/karazal/kas/api-ps/geoportail/";
    const urlapi1 ="/front/karazal/kas/api-ps/permisConstruire/";
    const getData = async () => {
       
        await axios.get(urlapi+`getPrefectureListPC`, {
          "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
          //console.log(response.data.data)
          var mydata = response.data.data
          //console.log(mydata)
          var table=[]
          var obj={}
          for (let i = 0; i < mydata.length; i++) {
            obj.label=mydata[i]['prefecture']
            obj.value=mydata[i]['prefecture']
            table.push(obj);
            obj={}
          }
          setprefOptions(table)
          console.log("setprefOptions is  ==> "+table[0])
        })
    };
   
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2014;
        const endYear = currentYear;
      
        const yearsArray = Array.from({ length: endYear - startYear + 1 }, (_, index) => {
          const year = startYear + index;
          return { label: year, value: year };
        });
        setyears(yearsArray)
        const getDatacom = async () => {
            var valeur = prefecture
            // if(valeur.includes("'")){
            //     valeur = valeur.replace(/'/g, "''");
            // }
        //    console.log("testapi++",urlapi1 + `getApiInfosEco?workspace=Urbanisme-PC-PH-Service Rendu/DIM_LIEU&prefecture=`+valeur)
            await axios.get(urlapi1 + `getApiInfosEco?workspace=Urbanisme-PC-PH-Service Rendu/DIM_LIEU&prefecture=`+valeur, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              //////console.log(response.data.data)
              var mydata = response.data.data
              //////console.log(mydata)
              var table=[]
              var obj={}
              for (let i = 0; i < mydata.length; i++) {
                obj.label=mydata[i]['ARRONDISSEMENT']
                obj.value=mydata[i]['ARRONDISSEMENT']
                table.push(obj);
                obj={}
              }
              if(prefecture){
                setcomOptions(table)
              }else{
                setcomOptions([])
              }
              
              ////console.log("this is length ==> "+table.length)
            })
          
      
        };
      getData();
      getDatacom();
    }, [prefecture]);


    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 40,
            backgroundColor: 'rgb(241 245 249)',
        }),
        menu: (provided) => ({
            ...provided,
            maxHeight: 300, 
        }),
    };

   
    const handleSelectChange = (selectedOption) => {
        if (selectedOption === null) {
            setSelectedOption(null);
            setprefecture('');
        } else {
            setSelectedOption(selectedOption);
            setprefecture(selectedOption['value']);
        }
    };
    const handleSelectChangecom = (selectedOption) => {
        //console.log(selectedOption == null)
        if (selectedOption === null) {
            setSelectedOption(null);
            setcommune('');
        } else {
            setSelectedOption(selectedOption);
            //console.log(selectedOption)
            setcommune(selectedOption['value']);
        }
    };
    const handleSelectChangyear = (selectedOption) => {
        console.log(selectedOption == null)
        if (selectedOption === null) {
            setSelectedOption(null);
            setyear('');
        } else {
            setSelectedOption(selectedOption);
            console.log('year year',selectedOption)
            setyear(selectedOption['value']);
        }
    };


    return (
        <>

            <HomeLayout>

                <div style={{ margin: "0 5% 0 5%" }} class="pb-5">
                    <Breadcrumbs home="Urbanisme" icon="pc2" text="Dossiers de permis de construire e-déposés" />
                    <div class="selectprefcom" style={{display:'flex' ,width: "100%",flexWrap:'wrap',gap:'20px',paddingTop:'10px',paddingBottom:'10px'}}>
                        <div >
                            <label>Préfecture et Province :</label>
                            <Select placeholder="Choisir une préfecture" options={prefOptions} onChange={handleSelectChange} styles={customStyles} isClearable={true} isMulti={false} />
                        </div>
                        <div >
                            <label>Commune et Arrondissement :</label>
                            <Select placeholder="Choisir une commune" options={comOptions} onChange={handleSelectChangecom} styles={customStyles} isClearable={true} isMulti={false}/>
                        </div>
                        <div >
                            <label>Année de délivrance :</label>
                            <Select placeholder="sélectionner une année" options={years} onChange={handleSelectChangyear} styles={customStyles} isClearable={true} isMulti={false}/>
                        </div>
                    </div>
                   



                    <div style={{ height: "100%",paddingTop:'12px' }} class="flex flex-row mb-2">
                        <ReactLogo />
                        <label class="font-sans md:font-serif font-semibold text-lg ml-2">Nombre de permis de construire délivrés</label>
                    </div>
                    <div class="rfcparti1">
                        <LineChart pref={prefecture} com={commune} annee={year}/>
                        <div class="bg-gray-100 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500" >
                            <div class="pcdounat" >
                                <div style={{display:'flex', justifyContent:'center',marginTop:'35px'}}>
                                <span class="font-sans md:font-serif font-medium " > % catégorie permis délivrés {year?' en '+year:''}</span>
                                </div>
                              
                                <div>
                                    <CustomActiveShapePieChart pref={prefecture} com={commune} annee={year}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{width:'100%',height:'20px'}}></div>
                    <div style={{ height: "100%",paddingTop:'12px',paddingBottom:'12px' }} class="flex flex-row mb-2">
                        <ReactLogo />
                        <span class="font-sans md:font-serif font-semibold text-lg ml-2" style={{width:'57%'}}>Distribution des permis de construire délivrés par nature de projet / type de projet</span>
                    </div>
                    <div class="rfcparti1">
                        <Barchartrechart pref={prefecture} com={commune} annee={year}/>
                        <div class="bg-gray-100	 col-span-1 row-span-2 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500" style={{ display: "block", width: "350px", height: "310px" }} >
                        <div style={{display:'flex', justifyContent:'center',paddingTop:'8px',paddingLeft:'20px',alignItems:'center'}}>
                                <span class="font-sans md:font-serif font-medium" > Distribution des permis de construire délivrés par type de projet {year?' en '+year:''}</span>
                                </div>
                            <div class="pl-10">
                             
                                <Piepercentage pref={prefecture} com={commune} annee={year}/>
                            </div>

                        </div>
                    </div>


                    <div style={{ height: "35px" ,marginBottom:'20px',paddingTop:'10px'}} class="flex flex-row mt-4">
                        <ReactLogo />
                        <label class="font-sans md:font-serif font-semibold text-lg ml-2"> Délais de délivrance des Permis de construire </label>
                    </div>

                    <div class="rfcparti1">
                        <LineChart2 pref={prefecture}   com={commune} annee={year}/>
                        <div class="monthscontainer">
                        <Demidoughnut1 pref={prefecture} com={commune} annee={year}/>
                        <Demidoughnut2 pref={prefecture} com={commune} annee={year}/>
                        </div>
                    </div>
                    <div style={{ height: "40px" ,marginBottom:'20px',paddingTop:'10px'}} class="flex flex-row ">
                        <ReactLogo />
                        <label class="font-sans md:font-serif font-semibold text-lg ml-2"> Evolution du nombre d’avis membre commission</label>
                    </div>

                    <div class="rfcparti1">
                        <BarChart pref={prefecture} com={commune} annee={year}/>
                        <div class="bg-gray-100	 col-span-1 row-span-2 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500" style={{ display: "block", width: "310px", height: "310px" }} >
                            <div class="pl-10">
                             
                                <PieChartWithNeedle pref={prefecture} annee={year}/>
                            </div>

                        </div>
                    </div>
                  
                </div>
            </HomeLayout>
        </>
    )
}
export default StatPC;