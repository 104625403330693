import { PieChart, Pie, Cell } from 'recharts';
import React, { useEffect, useState  } from 'react'
import axios from 'axios'


function PieChartWithNeedle(props) {

    const [Data, setData] = useState([]);

  const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";
    useEffect(() => {
        const getData = async () => {
            if(props.pref){
                var valeur = props.pref
                if(valeur.includes("'")){
                    valeur = valeur.replace(/'/g, "''");
                }
                var apipre;
                if(props.annee){
                    apipre=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Retard moyen pc&prefecture=` + valeur + `&annee=`+props.annee;
                }else{
                    apipre=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Retard moyen pc&prefecture=` + valeur
                }


                await axios.get(apipre, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
               
                setData(response.data.data)
            })
    
            }else{
                var preapi;
                if(props.annee){
                  preapi=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Retard moyen pc&annee=`+props.annee
                }else{
                  preapi=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Retard moyen pc`
                }

                await axios.get(preapi, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
               
                setData(response.data.data)
                
            })
    
            }
            
        };
        getData();
    }, [props.pref,props.annee]);
    var x=0
    if (Data.length === 0) {

    }
    if (Data.length !== 0) {
         x=Data[0]['Avg dureemoy']
    }
    
const RADIAN = Math.PI / 180;
const data = [
    { name: 'A', value: 5, color: 'rgba(115, 179, 96, 0.28)' },
    { name: 'd', value: 20, color: 'rgba(76, 196, 162, 0.22)' },
    { name: 'c', value: 20, color: 'rgba(115, 179, 96, 0.28)' },
    { name: 'd', value: 20, color: 'rgba(76, 196, 162, 0.22)' },


];
//const cx = 150;
//const cy = 200;
const iR = 50;
const oR = 100;
const value = 50;



const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;
    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path d={`M${xba} ${yba} L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="none" fill={color} />,
      <circle cx={cx} cy={cy} r={3} fill="#fff" stroke={color} strokeWidth={1} />,
      <circle cx={cx} cy={cy} r={2} fill={color} stroke="none" />,
    ];
  };
  
        return (
            <div style={{display:'flex',justifyContent:'center',flexDirection:'column',gap:'15px',paddingTop:'20PX'}}>
                <span class="font-sans md:font-serif font-MEDIUM">Retard émission PV commission (J) {props.annee?' en '+props.annee:''} </span>
                <PieChart width={700} height={150}>
                    <Pie
                        dataKey="value"
                        startAngle={180}
                        endAngle={0}
                        data={data}
                        cx={110}
                        cy={112}
                        innerRadius={iR}
                        outerRadius={oR}
                        fill="#8884d8"
                        stroke="none"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    {needle(value, data, 110, 112, iR, oR, '#d0d000')}
                </PieChart>
                <div className='spanpcdelai' >
                <h1 class="">{x}</h1>
                </div>
                </div>
                );
  }
export default PieChartWithNeedle
