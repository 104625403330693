import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';

import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


function LineChart2(props) {
    
    const [data, setData] = useState([]);

    const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";
    useEffect(() => {
        const getData = async () => {
            if(props.pref){
                var valeur;
                if(props.com){
                     valeur =props.com
                    if(valeur.includes("'")){
                        valeur = valeur.replace(/'/g, "''");
                    }
                    await axios.get(urlapi+`getApiInfosdml?workspace=Urbanisme-DML_RFC_RCP/Evolution de la durée moyenne de délivrance des dossier d'autorisation de démolition&arrondissementetap=` + valeur, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        setData(response.data.data)
                    })

                }else{
                     valeur =props.pref
                    if(valeur.includes("'")){
                        valeur = valeur.replace(/'/g, "''");
                    }
                    await axios.get(urlapi+`getApiInfosdml?workspace=Urbanisme-DML_RFC_RCP/Evolution de la durée moyenne de délivrance des dossier d'autorisation de démolition&prefectureEtap=` + valeur, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        setData(response.data.data)
                    })

                }
             
            }else{
                await axios.get(urlapi+`getApiInfosdml?workspace=Urbanisme-DML_RFC_RCP/Evolution de la durée moyenne de délivrance des dossier d'autorisation de démolition`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    setData(response.data.data)
                })
            }
    
          
        };
        getData();
    }, [props.pref,props.com]);
var Data ={}
if(data.length === 0){
     Data = {
        labels: ['2020', '2021', '2022', '2023', '2024'],
        datasets: [{
            label: ['Durée moyenne (j)'],
            data:[0,0,0,0],
            backgroundColor: [
                'rgba(194, 128, 162, 0.8)',
                'rgba(78, 141, 140, 0.8)',
                'rgba(152, 166, 92, 0.8)',
                'rgba(132, 216, 201, 0.8)',
                'rgba(74, 5, 79, 0.8)',
                'rgba(17, 63, 158, 0.8)'
            ],
            borderWidth: 4,
            borderColor: 'rgba(135,206,250,0.4)',
            tension: 0.2,
            hidden: false,
        }
        ]
    }
}
if(data.length !== 0){
     Data = {
        labels: data?.map(x => x["Date délivrance"]),
        datasets: [{
            label: 'Durée moyenne (j)',
            data:data?.map(x => x["Avg duree"]),
            backgroundColor: [
                'rgba(194, 128, 162, 0.8)',
                'rgba(78, 141, 140, 0.8)',
                'rgba(152, 166, 92, 0.8)',
                'rgba(132, 216, 201, 0.8)',
                'rgba(74, 5, 79, 0.8)',
                'rgba(17, 63, 158, 0.8)'
            ],
            borderWidth: 4,
            borderColor: 'rgba(135,206,250,0.4)',
            tension: 0.2,
            hidden: false,
        }
        ]
    }
}
    

    var option = {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: true,
          },
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
                display: true,
                text: 'Date délivrance'
            }
          },
          y: {
            grid: {
              display: true
            },
            title: {
                display: true,
                text: 'Durée moyenne (j)'
            }
          }
        }
      };
      
  return (
    <div class="bg-gray-100	 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500  respodiv" style={{ display: "block", height: "100%" }}>
    <div className="ml-2 items-center pt-3 pl-5 pr-5 ">
                <p class="font-sans md:font-serif font-medium pb-1">Evolution de la durée moyenne de délivrance des dossier d'autorisation de démolition</p>
                <div class="mb-2">
                </div>
            </div>
            <div class="flex flex-row ">
                  <div class="ml-3 mt-3" style={{ display: "block", width: "90%", height: "100%" }}>

                    <Line data={Data} options={option}/>

                </div>
            </div>
</div>
  )
}

export default LineChart2