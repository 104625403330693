import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, BarElement,CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';

import { Bar } from 'react-chartjs-2'
import axios from 'axios'


import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);


function LineChart2(props) {
 const urlapi = "/front/karazal/kas/api-ps/permisConstruire/";
    const [checkboxes, setCheckboxes] = useState({
        dataset1: true,
        dataset2: true
    });
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        console.log(checked)
        setCheckboxes({ ...checkboxes, [name]: checked });
    };

    const [Data, setData] = useState([]);

   
    useEffect(() => {
        const monthNames = [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ];
        const getData = async () => {
            if (props.pref) {
                var valeur = props.pref
                if(valeur.includes("'")){
                    valeur = valeur.replace(/'/g, "''");
                }
                if(props.com){
                    var valeur1 = props.com
                    if(valeur1.includes("'")){
                        valeur1 = valeur1.replace(/'/g, "''");
                    }
                    var apipre;
                    if(props.annee){
                        
                        apipre=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des autorisations de construire par mois?&arrondissement=` + valeur1 + `&annee=`+props.annee;
                        //console.log('test apipre',apipre)
                    }else{
                        apipre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des autorisations de construire?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="Durée etape PC"."ARRONDISSEMENT" ='` + valeur1 + `'`
                    }
    
                    await axios.get(apipre, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        if(props.annee){
                            setData(response.data?.data?.filter(item => item["datedelivrance"] !== "")?.map(item => {
                                const monthNumber = parseInt(item.datedelivrance.split('-')[0], 10);
                                const monthName = monthNames[monthNumber - 1]; 
                                return { ...item, datedelivrance: monthName };
                              }))
                        }else{
                            setData(response.data?.data?.filter(item => item["datedelivrance"] !== ""))
                        }
                    })
        
                }else{
                    var apiprecom;
                    if(props.annee){
                        apiprecom=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des autorisations de construire par mois?&prefecture=` + valeur + `'&annee=`+props.annee;
                        //console.log('test apipre',apipre)
                    }else{
                        apiprecom=urlapi+`getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu/Evolution de la durée moyenne de délivrance des autorisations de construire par mois?&prefecture=` + valeur
                    }
                    await axios.get(apiprecom, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        if(props.annee){
                            setData(response.data.data.filter(item => item["datedelivrance"] !== "").map(item => {
                                const monthNumber = parseInt(item.datedelivrance.split('-')[0], 10);
                                const monthName = monthNames[monthNumber - 1]; 
                                return { ...item, datedelivrance: monthName };
                              }))
                        }else{
                            setData(response.data.data.filter(item => item["datedelivrance"] !== ""))
                        }
                    })
        
                }
            
            } 
            else {
                var apiels;
                if(props.annee){
                    
                    apiels=urlapi+'getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu//Evolution de la durée moyenne de délivrance des autorisations de construire par mois&annee='+props.annee
                }else{
    
                    apiels=urlapi+'getApiInfos?workspace=Urbanisme-PC-PH-Service Rendu//Evolution de la durée moyenne de délivrance des autorisations de construire'
                }
                await axios.get(apiels, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    if(props.annee){
                        setData(response.data.data.filter(item => item["datedelivrance"] !== "").map(item => {
                            const monthNumber = parseInt(item.datedelivrance.split('-')[0], 10);
                            const monthName = monthNames[monthNumber - 1]; 
                            return { ...item, datedelivrance: monthName };
                          }))
                    }else{
                        setData(response.data.data.filter(item => item["datedelivrance"] !== ""))
                    }
                })
    
            }
    
        };
        getData();
    }, [props.pref,props.com,props.annee]);
    //console.log(Data.length)

    //console.log(startDate.getMonth())
var data ={}
    if (Data?.length === 0) {
         data = {
            labels: ['2019', '2020', '2021', '2022', '2023'],
            datasets: [{
                label: 'test1',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'rgba(194, 128, 162, 0.8)'
                ],
                borderWidth: 2,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset1,
            },
            {
                label: 'test2',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'green'
                ],
                borderWidth: 3,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset2,

            }
            ]
        }
    }
    function getUnique(array) {
        var uniqueArray = [];
        var i = 0
        // Loop through array values
        for (i = 0; i < array.length; i++) {
            if (uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }
    if (Data?.length !== 0) {
        var n = Data?.filter(x => x['categorie'] === 'Grands projets').map(x => x['Avg duree'])
        var l = Data?.filter(x => x['categorie'] === "Petits projets").map(x => x['Avg duree'])
        if (l?.length !== n?.length) {
            if (l?.length > n?.length) {
                n.unshift(0)
            } if (l?.length < n?.length) {
                l?.unshift(0)
            }
        }
         data = {
            labels: getUnique(Data?.map(x => x['datedelivrance'])),
            datasets: [{
                label: "Durée moyenne (en jours)",
                data: n,
                backgroundColor: [
                    'rgba(135,206,250,0.4)'
                ],
                borderWidth: 3,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.5,
                hidden: !checkboxes.dataset1,
            },
            {
                label: "Durée moyenne (en jours)",
                data: l,
                backgroundColor: [
                    'rgba(120,150,250,0.4)'
                ],
                borderWidth: 3,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.5,
                hidden: !checkboxes.dataset2,
            }
            ]
        }
    }
    var options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        title: {
            display: true,


        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Date délivrance'
                }
            },
            y: {
                grid: {
                    display: true,
                },
                title: {
                    display: true,
                    text: 'Durée moyenne (j)'
                }
            }
        },
        legend: {
            labels: {
                fontSize: 26
            }

        }
    }
    return (
        <div class="bg-gray-100	 row-span-2 col-span-2 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500 mt-8 respodiv" style={{ display: "block", height: "100%" }}>
            <div className="ml-2 items-center pt-3">
                <p class="font-sans md:font-serif font-medium pb-1" style={{paddingLeft:'15px'}}>Evolution  de la durée moyenne de délivrance des autorisations de construire {props.annee?' en '+props.annee:''}</p>
                <div class="mb-2">
                   
                </div>
            </div>

            <div class="flex flex-row " >
                <div class="ml-3 mt-3" style={{ display: "block", width: "80%", height: "260px" }}>

                {props.annee?( <Bar data={data} options={options} />):( <Line data={data} options={options} />)}

                </div>
                <div class="flex flex-col justify-center items-center place-content-center pt-20" style={{ display: "block", width: "20%" }}>
                    <div >

                        <input
                            type="checkbox"
                            name="dataset1"
                            checked={checkboxes.dataset1}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc1'
                        />
                        <label class="">Grands projets</label>


                    </div>
                    <div>


                        <input
                            type="checkbox"
                            name="dataset2"
                            checked={checkboxes.dataset2}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc2'
                        />
                        <label class="mr-3"> Petit projets </label>


                    </div>


                </div>
            </div>


        </div>
    )
}

export default LineChart2