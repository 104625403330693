import React, { useCallback, useEffect, useRef, useState} from "react";

import L from "leaflet";
import "leaflet.markercluster";
import { FadeLoader } from "react-spinners";
import "leaflet/dist/leaflet.css";
import "antd/dist/antd.min.css";
import "../components/Style.css";
import HomeLayout from "../components/HomeLayout";
import Breadcrumbs from "../components/BreadCrumbs";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

const StatMapv2 = () => {
  const mapRef = useRef(null);
  const markerClusterGroupRef = useRef(L.markerClusterGroup());
   const [isLoading, setIsLoading] = useState(true);
  const switcher = useRef(false);
  // const [mapRender, setMapRender] = useState(true);
  
  const newmap = useRef(null); 

    const fetchData = useCallback(
      async (bounds) => {
        setIsLoading(true);
        console.log('mapLoaded==========',mapRef.current);
        newmap.current.dragging.disable();
        newmap.current.scrollWheelZoom.disable();
        newmap.current.doubleClickZoom.disable();
        newmap.current.touchZoom.disable();
        newmap.current.boxZoom.disable();
        newmap.current.keyboard.disable();
        const { _southWest, _northEast } = bounds;
        const apiUrl = "/rokhas/api-geo/search/location";

        const urlencoded = new URLSearchParams();
        urlencoded.append("apiKey", "AB90G-BF903-W4EE1-Z6MQ9-7P22K");
        urlencoded.append("xmin", "" + _southWest.lng);
        urlencoded.append("xmax", "" + _northEast.lng);
        urlencoded.append("ymin", "" + _southWest.lat);
        urlencoded.append("ymax", "" + _northEast.lat);

        try {
          
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: urlencoded,
          });

          if (!response.ok) {
            setIsLoading(false);
            throw new Error("Network response was not ok");
          }

          const result = await response.json();
          // setData(result.data);
          var LOIcon = L.divIcon({
            // iconSize: null,

            html: ` 
          <?xml version="1.0" encoding="utf-8"?>
  
          <svg version="1.1" id="Uploaded to svgrepo.com" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
             width="100" height="100" viewBox="0 0 38 23" xml:space="preserve">
          <style type="text/css">
            .isometric_acht{fill:#F05A28;}
            .isometric_negen{fill:#BE1E2D;}
            .isometric_elf{fill:#b1bab8;}
            .isometric_twaalf{fill:#919e9b;}
            .st0{fill:#464d4a;}
            .st1{fill:#F28103;}
            .st2{fill:#7BD6C4;}
            .st3{fill:#F29227;}
            .st4{fill:#FF7344;}
            .st5{fill:#F8F7C5;}
            .st6{fill:#F5DF89;}
            .st7{fill:#AD9A74;}
            .st8{fill:none;}
            .st9{fill:#BE1E2D;}
            .st10{fill:#F2D76C;}
            .st11{fill:#72C0AB;}
            .st12{fill:#569080;}
          </style>
          <g>
            <path class="isometric_acht" d="M20.995,15.479c0.024-0.712-0.452-1.438-1.488-1.978v-1.321c1.201-0.619,1.548-1.395,1.488-2.054
              V8.606c-0.004-0.668-0.442-1.337-1.315-1.839c-1.767-1.018-4.623-1.023-6.379-0.012c-1.426,0.821-1.295,1.835-1.295,1.835v1.535
              c-0.045,0.645,0.3,1.414,1.488,2.033c0,0,0,1.431,0,1.321c-1.038,0.535-1.513,1.259-1.488,1.971v1.489
              c-0.049,0.702,0.381,1.414,1.315,1.952c1.767,1.018,4.623,1.023,6.379,0.012c0.935-0.539,1.36-1.256,1.295-1.964V15.479z"/>
            <path class="isometric_negen" d="M20.995,15.421v1.519c0.065,0.707-0.36,1.425-1.295,1.964c-1.756,1.011-4.612,1.006-6.379-0.012
              c-0.934-0.538-1.364-1.25-1.315-1.952v-1.535c0.004,0.665,0.432,1.331,1.315,1.839c1.767,1.018,4.623,1.023,6.379,0.012
              C20.578,16.75,20.998,16.086,20.995,15.421z M20.995,8.606c0.013,2.387-5.004,3.362-7.675,1.823
              c-0.883-0.509-1.311-1.175-1.315-1.839v1.535l0,0c-0.045,0.645,0.3,1.414,1.488,2.033v1.782h0.006
              c-0.015,0.456,0.275,0.916,0.881,1.265c1.178,0.679,3.082,0.682,4.253,0.008c0.607-0.35,0.891-0.813,0.866-1.273h0.009v-1.761
              c1.201-0.619,1.548-1.395,1.488-2.054l0,0V8.606z"/>
            <path class="isometric_elf" d="M15.5,19.583v5.537h0.002c0,0,0.997,1.878,0.998,1.88c0.001-0.001,0.999-1.88,1-1.88v-2.828
              c-0.389-1.005-0.881-1.905-1.463-2.661C15.857,19.62,15.678,19.607,15.5,19.583z"/>
            <path class="isometric_twaalf" d="M16.037,19.631c0.582,0.755,1.074,1.656,1.463,2.661v-2.701
              C17.019,19.652,16.527,19.662,16.037,19.631z"/>
          </g>
          </svg>
      `,
            className: "custom-icon",
            iconSize: [100, 100],
          });


       const displayDataOnMapcluster = (data) => {
         // Clear previous markers and clusters
         markerClusterGroupRef.current.clearLayers();
         clearMapLayers();

         if (data.length > 100) {
           // Use clustering for more than 100 markers
           data.forEach((item) => {
             const marker = L.marker([item.latitude, item.longitude], {
               icon: LOIcon,
             });

             marker.on("click", async () => {
               try {
                 const detailedData = await fetchDetailedData(item.id);
                 const popupContent = detailedData
                   ? `<div class="popup">
                 <div class="popup-content">
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Référence</span></div>
                     <div class="valeur">${detailedData.reference}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">numAutorisation</span></div>
                     <div class="valeur">${detailedData.numAutorisation}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Date délivrance</span></div>
                     <div class="valeur">${detailedData.dateDelivrance}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Titre foncier</span></div>
                     <div class="valeur">${detailedData.tfonciers}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Surface</span></div>
                     <div class="valeur">${detailedData.s_terrain} m&sup2;</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Nature</span></div>
                     <div class="valeur">${detailedData.nature}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Consistance</span></div>
                     <div class="valeur">${detailedData.consistence}</div>
                   </div>
                   <hr style="border: 1px solid #333; margin: 10px 0;width:40%">
                   <div class="data-item">
                     <div><i class="fa-regular fa-city" style="color: #373937;"></i></div>
                     <span class="label">${detailedData.prefecture}</span> 
                   </div>
                   <div class="data-item">
                     <div><i class="fa-regular fa-landmark-dome" style="color: #373937;"></i></div>
                     <span class="label">${detailedData.commune}</span> 
                   </div>
                   <div class="data-item">
                     <div><i class="fa-regular fa-map-location" style="color: #646568;"></i></div>
                     <span class="label">${detailedData.adresseProjet}</span> 
                   </div>
                 </div>
               </div>`
                   : `<p>ID: ${item.id}</p><p>Additional Data: Not available</p>`;

                 marker.bindPopup(popupContent).openPopup();
               } catch (error) {
                 console.error("Error fetching detailed data:", error);
                 marker
                   .bindPopup(
                     `<p>ID: ${item.id}</p><p>Additional Data: Not available</p>`
                   )
                   .openPopup();
               }
             });

             markerClusterGroupRef.current.addLayer(marker);
           });

           newmap.current.addLayer(markerClusterGroupRef.current);
         } else {
           // Add markers directly to the map without clustering
           data.forEach((item) => {
             const marker = L.marker([item.latitude, item.longitude], {
               icon: LOIcon,
             });

             marker.on("click", async () => {
               try {
                 const detailedData = await fetchDetailedData(item.id);
                 const popupContent = detailedData
                   ? `<div class="popup">
                 <div class="popup-content">
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Référence</span></div>
                     <div class="valeur">${detailedData.reference}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">numAutorisation</span></div>
                     <div class="valeur">${detailedData.numAutorisation}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Date délivrance</span></div>
                     <div class="valeur">${detailedData.dateDelivrance}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Titre foncier</span></div>
                     <div class="valeur">${detailedData.tfonciers}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Surface</span></div>
                     <div class="valeur">${detailedData.s_terrain} m&sup2;</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Nature</span></div>
                     <div class="valeur">${detailedData.nature}</div>
                   </div>
                   <div class="data-item">
                     <div class="prefixinfo"><span class="label-data-item">Consistance</span></div>
                     <div class="valeur">${detailedData.consistence}</div>
                   </div>
                   <hr style="border: 1px solid #333; margin: 10px 0;width:40%">
                   <div class="data-item">
                     <div><i class="fa-regular fa-city" style="color: #373937;"></i></div>
                     <span class="label">${detailedData.prefecture}</span> 
                   </div>
                   <div class="data-item">
                     <div><i class="fa-regular fa-landmark-dome" style="color: #373937;"></i></div>
                     <span class="label">${detailedData.commune}</span> 
                   </div>
                   <div class="data-item">
                     <div><i class="fa-regular fa-map-location" style="color: #646568;"></i></div>
                     <span class="label">${detailedData.adresseProjet}</span> 
                   </div>
                 </div>
               </div>`
                   : `<p>ID: ${item.id}</p><p>Additional Data: Not available</p>`;

                 marker.bindPopup(popupContent).openPopup();
               } catch (error) {
                 console.error("Error fetching detailed data:", error);
                 marker
                   .bindPopup(
                     `<p>ID: ${item.id}</p><p>Additional Data: Not available</p>`
                   )
                   .openPopup();
               }
             });

             newmap.current.addLayer(marker);
           });
         }
       };

            const displayDataOnMap = (data) => {
              markerClusterGroupRef.current.clearLayers();
              clearMapLayers();
              const values = data.map((item) => item.nbDossier);
              const maxValue = Math.max(...values);

              data.forEach((item) => {
                const radius = calculateRadius(item.nbDossier, maxValue);
                const circle = createCircle(item, radius);
                const icon = createIcon(item, radius);

                L.marker([item.latitude, item.longitude], { icon }).addTo(
                  newmap.current
                );

                circle.addTo(newmap.current); // Add the circle to the map
                circle.bindPopup(`<p>region: ${item.region}</p>`);
              });
            };

            const createCircle = (item, radius) => {
              return L.circle([item.latitude, item.longitude], {
                radius: radius,
                color: "blue", // Change color as needed
                fillColor: "#30f", // Change fill color as needed
                fillOpacity: 0.5, // Change opacity as needed
              });
            };

            const createIcon = (item, radius) => {
              const size = Math.max(radius / 10, 20);
              const iconHtml = `
    <div style="
      width: ${size}px;
      height: ${size}px;
      background-color: #007BFF; /* Bootstrap primary color */
      border: 2px solid #fff; /* White border for better visibility */
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      Opacity:0.70;
      color: white;
      font-size: 14px; /* Fixed text size */
      font-weight: bold; /* Bold text */
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
    ">
      ${item.nbDossier}
    </div>
  `;

              return L.divIcon({
                html: iconHtml,
                className: "custom-icon",
                iconSize: [size, size],
                iconAnchor: [size / 2, size / 2],
              });
            };

             LOIcon = L.divIcon({
              // iconSize: null,

              html: ` 
          <?xml version="1.0" encoding="utf-8"?>
  
          <svg version="1.1" id="Uploaded to svgrepo.com" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
             width="100" height="100" viewBox="0 0 38 23" xml:space="preserve">
          <style type="text/css">
            .isometric_acht{fill:#F05A28;}
            .isometric_negen{fill:#BE1E2D;}
            .isometric_elf{fill:#b1bab8;}
            .isometric_twaalf{fill:#919e9b;}
            .st0{fill:#464d4a;}
            .st1{fill:#F28103;}
            .st2{fill:#7BD6C4;}
            .st3{fill:#F29227;}
            .st4{fill:#FF7344;}
            .st5{fill:#F8F7C5;}
            .st6{fill:#F5DF89;}
            .st7{fill:#AD9A74;}
            .st8{fill:none;}
            .st9{fill:#BE1E2D;}
            .st10{fill:#F2D76C;}
            .st11{fill:#72C0AB;}
            .st12{fill:#569080;}
          </style>
          <g>
            <path class="isometric_acht" d="M20.995,15.479c0.024-0.712-0.452-1.438-1.488-1.978v-1.321c1.201-0.619,1.548-1.395,1.488-2.054
              V8.606c-0.004-0.668-0.442-1.337-1.315-1.839c-1.767-1.018-4.623-1.023-6.379-0.012c-1.426,0.821-1.295,1.835-1.295,1.835v1.535
              c-0.045,0.645,0.3,1.414,1.488,2.033c0,0,0,1.431,0,1.321c-1.038,0.535-1.513,1.259-1.488,1.971v1.489
              c-0.049,0.702,0.381,1.414,1.315,1.952c1.767,1.018,4.623,1.023,6.379,0.012c0.935-0.539,1.36-1.256,1.295-1.964V15.479z"/>
            <path class="isometric_negen" d="M20.995,15.421v1.519c0.065,0.707-0.36,1.425-1.295,1.964c-1.756,1.011-4.612,1.006-6.379-0.012
              c-0.934-0.538-1.364-1.25-1.315-1.952v-1.535c0.004,0.665,0.432,1.331,1.315,1.839c1.767,1.018,4.623,1.023,6.379,0.012
              C20.578,16.75,20.998,16.086,20.995,15.421z M20.995,8.606c0.013,2.387-5.004,3.362-7.675,1.823
              c-0.883-0.509-1.311-1.175-1.315-1.839v1.535l0,0c-0.045,0.645,0.3,1.414,1.488,2.033v1.782h0.006
              c-0.015,0.456,0.275,0.916,0.881,1.265c1.178,0.679,3.082,0.682,4.253,0.008c0.607-0.35,0.891-0.813,0.866-1.273h0.009v-1.761
              c1.201-0.619,1.548-1.395,1.488-2.054l0,0V8.606z"/>
            <path class="isometric_elf" d="M15.5,19.583v5.537h0.002c0,0,0.997,1.878,0.998,1.88c0.001-0.001,0.999-1.88,1-1.88v-2.828
              c-0.389-1.005-0.881-1.905-1.463-2.661C15.857,19.62,15.678,19.607,15.5,19.583z"/>
            <path class="isometric_twaalf" d="M16.037,19.631c0.582,0.755,1.074,1.656,1.463,2.661v-2.701
              C17.019,19.652,16.527,19.662,16.037,19.631z"/>
          </g>
          </svg>
      `,
              className: "custom-icon",
              iconSize: [100, 100],
            });


          if (result.groupby === "dossier") {
            displayDataOnMapcluster(result.data);
          } else {
             displayDataOnMap(result.data);

          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        setIsLoading(false);
         newmap.current.dragging.enable();
         newmap.current.scrollWheelZoom.enable();
         newmap.current.doubleClickZoom.enable();
         newmap.current.touchZoom.enable();
         newmap.current.boxZoom.enable();
         newmap.current.keyboard.enable();


      },
      [ ]
    );
  useEffect(() => {
    console.log('useEffect1')
    if (!newmap.current) {
      const mapInstance = L.map(mapRef.current, {
        center: [30.002517, -14.224784],
        zoom: 6,
        dragging: true,
        renderer: L.canvas(),
      });

      L.tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }).addTo(mapInstance);

      markerClusterGroupRef.current.addTo(mapInstance);

      newmap.current = mapInstance;
      
    }
  

    return () => {
      if (newmap.current) {
        newmap.current.remove();
        newmap.current = null;
      }
    };
  }, []);
useEffect(() => {
  if (!newmap.current) {
    return;
  }
  const staticBounds = {
    _southWest: { lat: 23.200960808078566, lng: -31.508789062500004 },
    _northEast: { lat: 36.38591277287654, lng: 3.0761718750000004 },
  };
  newmap.current.on("load", fetchData(staticBounds));
  newmap.current.on("moveend", () => {
    if (newmap.current.getZoom() >= 10) {
      fetchData(newmap.current.getBounds());
    }
  })
  newmap.current.on("zoomend", () => {
    console.log("loader Current value====", newmap.current.getZoom());
    if (
      newmap.current.getZoom() < 10 &&
      newmap.current.getZoom() >= 6 &&
      switcher.current
    ) {
      switcher.current = false;
      fetchData(staticBounds);
    } else if (newmap.current.getZoom() >= 10 && !switcher.current) {
      switcher.current = true;
    }
     else if (newmap.current.getZoom() < 6) {
      switcher.current = true;
      clearMapLayers();
    }
  });
  return () => {
    newmap.current.off("load");
    newmap.current.off("moveend");
    newmap.current.off("zoomend");
  };
}, [fetchData]);




  // useEffect(() => {
  //   if (!globalemap) {
  //      console.log("useEffect2");
  //     console.log("global+++");
  //     fetchData(globalbounds);
  //   }
  // }, [globalemap, fetchData, globalbounds]);

//   useEffect(() => {
//      console.log("useEffect3");
//     const handleMoveEnd = (zoomlevel) => {
//       console.log("++++++++ridaa", zoomlevel);
//       if (zoomlevel >= 10) {
//         const bounds = newmap.current.getBounds();

//         if (fetchTimeoutRef.current) {
//           clearTimeout(fetchTimeoutRef.current);
//         }
//         fetchTimeoutRef.current = setTimeout(() => {
//           fetchData(bounds);
//         }, 400);
//       } else if (zoomlevel < 10) {
//         console.log("changerendring+++++");
//         setMapRender(true);
//       }
//     };

//     const handleZoomEnd = () => {
//       const zoomLevel = newmap.current.getZoom();
//       if (zoomLevel >= 10) {
//         setglobalemap(true);
//         console.log("zooom+++++", zoomLevel);
//         if (moveEndHandlerRef.current) {
//           newmap.current.off("moveend", moveEndHandlerRef.current);
//         }
//         moveEndHandlerRef.current = () => handleMoveEnd(zoomLevel);
//         newmap.current.on("moveend", moveEndHandlerRef.current);
//       } else if (zoomLevel < 10) {
//         markerClusterGroupRef.current.clearLayers();
//         setglobalemap(false);
//         if (moveEndHandlerRef.current) {
//           newmap.current.off("moveend", moveEndHandlerRef.current);
//           moveEndHandlerRef.current = null; 
//         }
//       }
//     };

//     if (newmap.current) {
//       newmap.current.on("zoomend", handleZoomEnd);
//     }
// const abortController = abortControllerRef.current;
//     return () => {
//       if (fetchTimeoutRef.current) {
//         clearTimeout(fetchTimeoutRef.current);
//       }
//       if (abortController) {
//         abortController.abort();
//       }
//       if (newmap.current) {
//         newmap.current.off("zoomend", handleZoomEnd);
//         newmap.current.off("moveend");
//       }
//     };
//   }, [fetchData]);





  const fetchDetailedData = async (id) => {
    const apiUrl = "/rokhas/api-geo/search/popupInfo";

    const urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BF903-W4EE1-Z6MQ9-7P22K");
    urlencoded.append("doid", "" + id);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlencoded,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch detailed data");
      }
      const detailedData = await response.json();
      return detailedData.data;
    } catch (error) {
      console.error("Error fetching detailed data:", error);
      return null;
    }
  };

  const calculateRadius = (value, maxValue) => {
    const minRadius = 400;
    const maxRadius = 550;
    const normalizedRadius =
      minRadius + (value / maxValue) * (maxRadius - minRadius);
    return Math.max(normalizedRadius, maxRadius / 2);
  };
  const clearMapLayers = () => {
    newmap &&
      newmap.current.eachLayer((layer) => {
        if (layer instanceof L.Circle || layer instanceof L.Marker) {
          newmap.current.removeLayer(layer);
        }
      });
  };

  return (
    <HomeLayout>
      <div
        style={{
          margin: "0 5% 0 5%",
          width: "90%",
          height: "850px",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Breadcrumbs
          home="Géoportail"
          icon="pc2"
          text="Autorisations urbanistiques"
        />

        <div
          style={{
            position: "relative",
            display: "flex",
            width: "92%",
            height: "100%",
            flexDirection: "column",
            paddingTop: "20px",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: "9000000000",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></div>
            <div
              style={{
                position: "absolute",
                zIndex: "9000000000",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <FadeLoader
                color="#8BB2AA"
                height={31}
                width={12}
                margin={20}
                radius={11}
                loading={isLoading}
              />
            </div>
          <div
            ref={mapRef}
            className="mapp"
            style={{ border: "1px black solid" }}
          ></div>
        </div>
      </div>
    </HomeLayout>
  );
};
export default StatMapv2;
